import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';

import type { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { useRouter } from 'next/router';

import { Box } from '@mui/material';
import cookie from 'cookie';

import { getCountry } from 'api/auth';

import Head from 'components/atoms/Head';
import CreateMenu from 'components/molecules/CreateMenu';
import MainMenu from 'components/molecules/MainMenu';
import MainPageAll from 'components/organisms/MainPage/MainPageAll';
import MainPageForYou from 'components/organisms/MainPage/MainPageForYou';
import MainPageLive from 'components/organisms/MainPage/MainPageLive';
import MainPageNew from 'components/organisms/MainPage/MainPageNew';
import MainPageSport from 'components/organisms/MainPage/MainPageSport';
import MainPageBanner from 'components/organisms/MainPageBanner';
import Layout from 'components/templates/Layout';

import useStudioAdSetting from 'hooks/useStudioAdSetting';

import getThemeCookies from 'utils/getThemeCookies';

const MainPage = ({ countryFromCookie }: { countryFromCookie: string }) => {
  const { events, query, replace } = useRouter();
  const { showMainPageBanner, mainPageBannerInfo } = useStudioAdSetting();

  const [activeMenu, setActiveMenu] = useState('all');
  const [country, setCountry] = useState(countryFromCookie);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleClickMenu = (selectedMenu: string) => {
    setActiveMenu(selectedMenu);
  };

  useEffect(() => {
    const getCountryFromIp = async () => {
      const countryFromIp = await getCountry();
      setCountry(countryFromIp);
    };

    if (countryFromCookie === '') getCountryFromIp();
  }, [countryFromCookie]);

  useEffect(() => {
    if (
      'scrollRestoration' in history &&
      history.scrollRestoration !== 'manual'
    ) {
      history.scrollRestoration = 'manual';
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      sessionStorage.setItem(
        'scrollPosition',
        JSON.stringify({
          scroll: window.scrollY,
          storageActiveMenu: activeMenu,
        }),
      );
    };
    events.on('routeChangeStart', handleRouteChange);
    return () => {
      events.off('routeChangeStart', handleRouteChange);
    };
  }, [activeMenu, events]);

  useEffect(() => {
    if (query.scrollToTop === 'true') {
      window.scrollTo(0, 0);
      replace('/', undefined, { shallow: true });
      return;
    }

    if ('scrollPosition' in sessionStorage) {
      const storageItem = sessionStorage.getItem('scrollPosition');
      if (!storageItem) return;

      const { scroll, storageActiveMenu } = JSON.parse(storageItem);

      setActiveMenu(storageActiveMenu || 'all');

      window.scrollTo(0, scroll);
      sessionStorage.removeItem('scrollPosition');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        width: { sm: '600px', md: '900px', lg: '1200px' },
        overflow: 'hidden',
        mx: 'auto',
      }}
    >
      <Head />

      <Box
        sx={{
          display: { xs: 'none', sm: 'flex' },
          flexDirection: { sm: 'column', lg: 'row' },
          gap: { sm: '16px', lg: '24px' },
          mx: { sm: '40px', lg: 0 },
        }}
      >
        {showMainPageBanner ? (
          <MainPageBanner mainPageBannerInfo={mainPageBannerInfo} />
        ) : null}
        <CreateMenu.MainPageTop />
      </Box>

      <Box sx={{ position: 'relative' }}>
        <MainMenu activeMenu={activeMenu} onClick={handleClickMenu} />
      </Box>

      {
        {
          all: <MainPageAll country={country} timezone={timezone} />,
          forYou: <MainPageForYou country={country} timezone={timezone} />,
          live: <MainPageLive country={country} timezone={timezone} />,
          new: <MainPageNew country={country} timezone={timezone} />,
          baseball: (
            <MainPageSport
              country={country}
              initSportType="baseball"
              timezone={timezone}
            />
          ),
          soccer: (
            <MainPageSport
              country={country}
              initSportType="soccer"
              timezone={timezone}
            />
          ),
          basketball: (
            <MainPageSport
              country={country}
              initSportType="basketball"
              timezone={timezone}
            />
          ),
          esports: (
            <MainPageSport
              country={country}
              initSportType="esports"
              timezone={timezone}
            />
          ),
          sports: (
            <MainPageSport
              country={country}
              initSportType="all"
              timezone={timezone}
            />
          ),
        }[activeMenu]
      }
    </Box>
  );
};

MainPage.getLayout = (page: ReactElement) => <Layout>{page}</Layout>;

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext,
) => {
  const { country = '', locale: localeFromCookie } = cookie.parse(
    context.req.headers.cookie ?? '',
  );

  const { theme, themeSource } = getThemeCookies(context.req.headers.cookie);

  // 쿠키와 브라우저 언어정보가 다를때 쿠키에 저장된 언어로 리다이렉트, en은 기본값으로 대체
  if (localeFromCookie && context.locale !== localeFromCookie) {
    const resolvedUrlWithoutDefault =
      context.resolvedUrl === '/' ? '' : context.resolvedUrl;

    return {
      redirect: {
        destination: `/${localeFromCookie}${resolvedUrlWithoutDefault}`,
        permanent: false,
        props: {
          theme,
          themeSource,
        },
      },
    };
  }

  return {
    props: {
      countryFromCookie: country,
      theme,
      themeSource,
    },
  };
};

export default MainPage;
