import { useEffect, useMemo, useRef } from 'react';

import useTranslation from 'next-translate/useTranslation';

import { Box, CircularProgress, Skeleton, Typography } from '@mui/material';
import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';

import { getRecommendChannelList } from 'api/channel';
import { getEventList } from 'api/event';
import useScoreboardApi from 'api/hooks/useScoreboardApi';
import {
  getAllMatchList,
  getRecommendMatchList,
  getScheduledMatchList,
} from 'api/scoreboard';

import BoxWithArrowIcon from 'components/molecules/BoxWithArrowIcon';
import MatchCardWrapper from 'components/molecules/MatchCardWrapper';
import EventCardWrapper from 'components/organisms/EventCardWrapper';
import PublicChannelListCard from 'components/organisms/PublicChannelListCard';
import MatchCardSkeletons from 'components/organisms/PublicMatchList/PublicMatchListCard/MatchCardSkeletons';
import PublicMatchListCardWrapper from 'components/organisms/PublicMatchListCardWrapper';
import ComponentGuard from 'components/templates/ComponentGuard';

import useActiveAuth from 'hooks/useActiveAuth';
import useCheckDevice from 'hooks/useCheckDevice';
import useTanstackQuery from 'hooks/useTanstackQuery';

import { getEventQueries, getMatchQueries } from 'utils/getQueries';

import ResponsiveBannerAdCard from '../GoogleAds/ResponsiveBannerAdCard';

const MainPageAllDivider = () => (
  <Box
    sx={({ palette: { mode } }) => ({
      display: { xs: 'block', sm: 'none' },
      mt: 3,
      height: '10px',
      bgcolor: mode === 'dark' ? '#1C2120' : '#F2F5F3',
    })}
  />
);

const MainPageAll = ({
  country,
  timezone,
}: {
  country: string;
  timezone: string;
}) => {
  const { getForYouMatchList } = useScoreboardApi();
  const {
    isProfileLoadedUser,
    isAnonymousUser,
    isProfileLoadProcessing,
    isProfileLoadProcessComplete,
    user,
    channel,
    token,
    isSubscribedUser,
  } = useActiveAuth();
  const { infiniteQueryOptions } = useTanstackQuery();
  const { t } = useTranslation('main');
  const { isMobile } = useCheckDevice();

  const endPoint = useRef(null);
  const cardWrapperEl = useRef<HTMLDivElement>(null);

  const hasInterestSportsUser = useMemo(
    () =>
      isProfileLoadedUser &&
      channel.interests !== null &&
      channel.interests.length > 0,
    [channel.interests, isProfileLoadedUser],
  );

  const { data: recommendMatchList, isFetching: isRecommendMatchListFetching } =
    useQuery({
      queryKey: ['matchList', 'recommendMatch', user.id],
      queryFn: async () =>
        await getRecommendMatchList(
          getMatchQueries({
            uid: user.id,
            offset: 0,
            limit: 10,
            timezone,
            country: user.country,
            language: user.language,
          }),
        ),
      enabled: isProfileLoadedUser,
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    });

  const {
    data: recommendChannelList,
    isLoading: isGetRecommendChannelListLoading,
  } = useQuery({
    queryKey: ['channelList', 'recommendChannel'],
    queryFn: () =>
      getRecommendChannelList(
        token,
        getMatchQueries({ offset: 0, limit: 10, country }),
      ),
    enabled: isProfileLoadProcessComplete,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const { data: scheduledMatchList, isFetching: isScheduledMatchListFetching } =
    useQuery({
      queryKey: ['matchList', 'scheduledMatch', channel.channelId],
      queryFn: () =>
        getScheduledMatchList(
          getMatchQueries({
            country: country ?? 'KR',
            disclosure: 'public',
            language:
              user && user.language ? user.language : window.navigator.language,
            timezone,
            offset: 0,
            limit: 10,
          }),
        ),
      enabled: isProfileLoadProcessComplete,
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    });

  // 비로그인 혹은 관심종목이 없는 사용자
  const allMatchListData = useInfiniteQuery({
    queryKey: ['matchList', 'allMatch', channel.channelId],
    queryFn: async ({ pageParam = 0 }) =>
      await getAllMatchList(
        getMatchQueries({
          country,
          disclosure: 'public',
          language:
            user && user.language ? user.language : window.navigator.language,
          timezone,
          offset: pageParam,
        }),
      ),
    enabled:
      isAnonymousUser ||
      (isProfileLoadProcessComplete && !hasInterestSportsUser),
    ...infiniteQueryOptions,
  });

  const staffPickEventListData = useQuery({
    queryKey: ['eventList', 'staffPick', channel.channelId],
    queryFn: async () =>
      await getEventList(
        getEventQueries({
          orderByCountry: country,
          orderByLanguage:
            user && user.language ? user.language : window.navigator.language,

          useOrderByDeadlineImminent: true,
        }),
      ),
    enabled:
      isAnonymousUser ||
      (isProfileLoadProcessComplete && !hasInterestSportsUser),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  // 관심종목이 있는 로그인 사용자
  const favoriteSportsMatchListData = useInfiniteQuery({
    queryKey: [
      'matchList',
      'forYouMatch',
      channel.interests,
      channel.channelId,
    ],
    queryFn: async ({ pageParam = 0 }) =>
      await getForYouMatchList(
        getMatchQueries({
          country,
          disclosure: 'public',
          language:
            user && user.language ? user.language : window.navigator.language,
          timezone,
          offset: pageParam,
        }),
      ),
    enabled: isProfileLoadProcessComplete && hasInterestSportsUser,
    ...infiniteQueryOptions,
  });

  const favoriteSportsEventListData = useQuery({
    queryKey: [
      'eventList',
      'forYouEvent',
      channel.interests,
      channel.channelId,
    ],
    queryFn: async () =>
      await getEventList(
        getEventQueries({
          orderByCountry: country,
          orderByLanguage:
            user && user.language ? user.language : window.navigator.language,

          eventTypesFilter: ['sport'],
          sportTypesFilter: channel.interests || undefined,

          useOrderByDeadlineImminent: true,
        }),
      ),
    enabled: isProfileLoadProcessComplete && hasInterestSportsUser,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const {
    data: allMatchList,
    fetchNextPage: allMatchFetchNextPage,
    hasNextPage: allMatchHasNextPage,
    isLoading: allMatchIsInitialLoading,
    isFetching: allMatchIsFetching,
    isFetchingNextPage: allMatchIsFetchingNextPage,
  } = hasInterestSportsUser ? favoriteSportsMatchListData : allMatchListData;

  const { data: eventList, isFetching: isEventListDataFetching } =
    hasInterestSportsUser
      ? favoriteSportsEventListData
      : staffPickEventListData;

  useEffect(() => {
    let endPointCurrent = endPoint.current;

    const callBack = async (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        if (allMatchHasNextPage && !allMatchIsFetchingNextPage) {
          await allMatchFetchNextPage();
        }
      }
    };

    const observer = new IntersectionObserver(callBack, {
      rootMargin: '100px',
    });

    if (endPoint.current) {
      endPointCurrent = endPoint.current;
      observer.observe(endPoint.current);
    }

    return () => {
      if (endPointCurrent) observer.unobserve(endPointCurrent);
    };
  }, [allMatchFetchNextPage, allMatchHasNextPage, allMatchIsFetchingNextPage]);

  return (
    <>
      <ComponentGuard>
        <MatchCardWrapper
          idForGTM="gtm-recommend-match-area"
          title={t('subTitle.recommendContent', {
            name: channel.channelName,
          })}
          matchListData={recommendMatchList}
          isFetching={isRecommendMatchListFetching}
          wrapperSx={{
            mt: { xs: '20px', sm: '28px', lg: 4 },
            mb: { xs: '20px', sm: '28px', lg: 7 },
          }}
        />

        {isSubscribedUser ? <MainPageAllDivider /> : null}

        <ResponsiveBannerAdCard adId="메인_첫번째컨텐츠리스트하단_배너_공통" />
      </ComponentGuard>

      <EventCardWrapper
        eventListData={eventList}
        isFetching={isEventListDataFetching}
      />

      {!isAnonymousUser ? (
        <ResponsiveBannerAdCard adId="메인_첫번째컨텐츠리스트하단_배너_공통" />
      ) : null}

      <MainPageAllDivider />

      <MatchCardWrapper
        title={t('subTitle.scheduled')}
        matchListData={scheduledMatchList}
        isFetching={isScheduledMatchListFetching}
        wrapperSx={{ mt: { xs: '20px', sm: 5, lg: '50px' } }}
      />

      <MainPageAllDivider />

      <Box
        sx={{
          mt: { xs: '20px', sm: 5, lg: 8 },
          mb: 2,
        }}
      >
        <Typography
          variant="h3Bold"
          sx={{
            mb: { xs: '2px', sm: 1 },
            fontSize: { xs: '20px', sm: '24px' },
            ml: { xs: 2, sm: 5, lg: 0 },
          }}
        >
          {t('subTitle.recommendChannel')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            display: { xs: 'none', sm: 'block' },
            mb: { sm: '12px', lg: '14px' },
            ml: { xs: 2, sm: 5, lg: 0 },
          }}
        >
          {t('description.recommendChannel')}
        </Typography>

        <BoxWithArrowIcon childrenWrapperRef={cardWrapperEl} scrollGap={250}>
          <Box
            className="gtm-channel-list-area"
            ref={cardWrapperEl}
            sx={{
              position: 'relative',
              display: 'flex',
              columnGap: { xs: '12px', lg: 2 },
              overflowX: 'auto',
              py: '8px',
              scrollSnapType: { lg: 'x mandatory' },
              px: { xs: 2, sm: 5, lg: 0 },
              '::-webkit-scrollbar': { display: 'none' },
            }}
          >
            {isProfileLoadProcessing || isGetRecommendChannelListLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  columnGap: { xs: '12px', lg: 2 },
                  height: '330px',
                }}
              >
                {[1, 2, 3, 4, 5].map((value) => (
                  <Skeleton
                    key={value}
                    sx={{
                      mt: '-110px',
                      borderRadius: '6px',
                      aspectRatio: '1 / 1.3',
                      width: '250px',
                      height: '530px',
                    }}
                  />
                ))}
              </Box>
            ) : (
              recommendChannelList?.channels.map((channelData, idx) => (
                <PublicChannelListCard.Card
                  key={idx}
                  isMyChannel={user?.id === channelData.uid}
                  channelData={channelData}
                  summaryMode
                />
              ))
            )}
          </Box>
        </BoxWithArrowIcon>
      </Box>

      <ResponsiveBannerAdCard adId="메인_전체경기상단_배너_공통" />

      <MainPageAllDivider />

      <Box
        sx={{
          mt: { xs: '20px', sm: 3, lg: 6 },
          mx: { xs: 0, sm: 5, lg: 0 },
        }}
      >
        <Typography
          variant="h3Bold"
          sx={{
            mb: { xs: '10px', sm: '12px', lg: '22px' },
            mx: { xs: 2, sm: 0 },
            fontSize: { xs: '20px', sm: '24px' },
          }}
        >
          {t('subTitle.allContent')}
        </Typography>
        <PublicMatchListCardWrapper
          tabType="staffPick"
          resultPages={allMatchList?.pages}
          isLoading={
            allMatchIsInitialLoading ||
            (allMatchIsFetching && !allMatchIsFetchingNextPage)
          }
        />
      </Box>

      {allMatchIsFetchingNextPage ? (
        <Box sx={{ mt: 2, mx: { xs: 0, sm: '26px', lg: 0 } }}>
          {!isMobile ? (
            <MatchCardSkeletons
              mode="default"
              sx={{ px: { xs: 0, sm: 2, lg: 0 } }}
            />
          ) : null}
          <Box textAlign="center">
            <CircularProgress size={30} sx={{ color: 'gray.500', mt: 3 }} />
          </Box>
        </Box>
      ) : (
        <Box ref={endPoint} />
      )}
    </>
  );
};

export default MainPageAll;
