import { useEffect, useRef } from 'react';

import useTranslation from 'next-translate/useTranslation';

import { Box, CircularProgress, Typography } from '@mui/material';
import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';

import { getEventList } from 'api/event';
import { getNewMatchList } from 'api/scoreboard';

import EventCardWrapper from 'components/organisms/EventCardWrapper';
import MatchCardSkeletons from 'components/organisms/PublicMatchList/PublicMatchListCard/MatchCardSkeletons';
import PublicMatchListCardWrapper from 'components/organisms/PublicMatchListCardWrapper';

import useActiveAuth from 'hooks/useActiveAuth';
import useCheckDevice from 'hooks/useCheckDevice';
import useTanstackQuery from 'hooks/useTanstackQuery';

import { getEventQueries, getMatchQueries } from 'utils/getQueries';

import ResponsiveBannerAdCard from '../GoogleAds/ResponsiveBannerAdCard';

const MainPageNew = ({
  country,
  timezone,
}: {
  country: string;
  timezone: string;
}) => {
  const { user, isProfileLoadProcessComplete, channel } = useActiveAuth();
  const { infiniteQueryOptions } = useTanstackQuery();
  const { t } = useTranslation('main');
  const { isMobile } = useCheckDevice();

  const endPoint = useRef(null);

  const { data: newEventListData, isFetching: isNewEventListDataFetching } =
    useQuery({
      queryKey: ['eventList', 'newEvent', channel.channelId],
      queryFn: async () =>
        await getEventList(
          getEventQueries({
            orderByCountry: country,
            orderByLanguage:
              user && user.language ? user.language : window.navigator.language,

            orderByCreatedAt: 'DESC',
            useOrderByAdminPick: false,
          }),
        ),
      enabled: isProfileLoadProcessComplete,
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    });

  const {
    data: newMatchList,
    fetchNextPage: newMatchFetchNextPage,
    hasNextPage: newMatchHasNextPage,
    isLoading: newMatchIsInitialLoading,
    isFetching: newMatchIsFetching,
    isFetchingNextPage: newMatchIsFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['matchList', 'newMatch', channel.channelId],
    queryFn: ({ pageParam = 0 }) =>
      getNewMatchList(
        getMatchQueries({
          country,
          language:
            user && user.language ? user.language : window.navigator.language,
          timezone,
          offset: pageParam,
        }),
      ),
    ...infiniteQueryOptions,
    enabled: isProfileLoadProcessComplete,
  });

  useEffect(() => {
    let endPointCurrent = endPoint.current;

    const callBack = async (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;

      if (entry.isIntersecting && !newMatchIsFetchingNextPage) {
        if (newMatchHasNextPage) {
          await newMatchFetchNextPage();
        }
      }
    };

    const observer = new IntersectionObserver(callBack, {
      rootMargin: '100px',
    });

    if (endPoint.current) {
      endPointCurrent = endPoint.current;
      observer.observe(endPoint.current);
    }

    return () => {
      if (endPointCurrent) observer.unobserve(endPointCurrent);
    };
  }, [newMatchFetchNextPage, newMatchHasNextPage, newMatchIsFetchingNextPage]);

  return (
    <>
      <EventCardWrapper
        eventListData={newEventListData}
        isFetching={isNewEventListDataFetching}
      />

      <ResponsiveBannerAdCard adId="메인_첫번째컨텐츠리스트하단_배너_공통" />

      <Box
        sx={{
          mt: { xs: 3, sm: 5, lg: 9 },
          mx: { xs: 0, sm: '40px', lg: 0 },
        }}
      >
        <Typography
          variant="h3Bold"
          sx={{
            mx: { xs: 2, sm: 0 },
            mb: { xs: '10px', sm: '12px', lg: '22px' },
            fontSize: { xs: '20px', sm: '24px' },
          }}
        >
          {t('subTitle.match')}
        </Typography>
        <PublicMatchListCardWrapper
          tabType="staffPick"
          resultPages={newMatchList?.pages}
          isLoading={
            newMatchIsInitialLoading ||
            (newMatchIsFetching && !newMatchIsFetchingNextPage)
          }
        />
      </Box>

      {newMatchIsFetchingNextPage ? (
        <Box sx={{ mx: { xs: 0, sm: '40px', lg: 0 } }}>
          {!isMobile ? (
            <MatchCardSkeletons
              mode="default"
              sx={{ px: { xs: 0, sm: 2, lg: 0 } }}
            />
          ) : null}
          <Box textAlign="center">
            <CircularProgress size={30} sx={{ color: 'gray.500' }} />
          </Box>
        </Box>
      ) : (
        <Box ref={endPoint} />
      )}
    </>
  );
};

export default MainPageNew;
