import { useEffect, useRef, useState } from 'react';

import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import {
  Box,
  CircularProgress,
  Divider,
  Typography,
  useTheme,
} from '@mui/material';
import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';

import { getEventList } from 'api/event';
import { getAllMatchList } from 'api/scoreboard';

import EventCardWrapper from 'components/organisms/EventCardWrapper';
import MatchCardSkeletons from 'components/organisms/PublicMatchList/PublicMatchListCard/MatchCardSkeletons';
import PublicMatchListCardWrapper from 'components/organisms/PublicMatchListCardWrapper';

import useActiveAuth from 'hooks/useActiveAuth';
import useCheckDevice from 'hooks/useCheckDevice';
import useTanstackQuery from 'hooks/useTanstackQuery';

import type { SportType } from 'types/scoreboardData';

import { getEventQueries, getMatchQueries } from 'utils/getQueries';
import sportTypeList from 'utils/sportTypeList';

import ResponsiveBannerAdCard from '../GoogleAds/ResponsiveBannerAdCard';
import SportsIconTabs from '../SportsIconTabs';

const MainPageSport = ({
  country,
  initSportType,
  timezone,
}: {
  country: string;
  initSportType: SportType;
  timezone: string;
}) => {
  const router = useRouter();
  const { user, isProfileLoadProcessComplete, channel } = useActiveAuth();
  const { infiniteQueryOptions } = useTanstackQuery();
  const {
    palette: { mode },
  } = useTheme();
  const { t } = useTranslation('main');
  const { isMobile } = useCheckDevice();

  const [selectedSportType, setSelectedSportType] = useState(initSportType);

  const endPoint = useRef(null);

  const reorderedSportPlusTabTypeList = sportTypeList.filter((sportType) => {
    const displayedSportTypeList = [
      'baseball',
      'basketball',
      'soccer',
      'esports',
    ];
    return !displayedSportTypeList.includes(sportType);
  });

  const { data: sportEventListData, isFetching: isSportEventListDataFetching } =
    useQuery({
      queryKey: [
        'eventList',
        'sportEvent',
        selectedSportType,
        channel.channelId,
      ],
      queryFn: async () =>
        await getEventList(
          getEventQueries({
            orderByCountry: country,
            orderByLanguage:
              user && user.language ? user.language : window.navigator.language,

            eventTypesFilter: ['sport'],
            sportTypesFilter:
              selectedSportType === 'all' ? undefined : [selectedSportType],

            useOrderByDeadlineImminent: true,
          }),
        ),
      enabled: isProfileLoadProcessComplete,
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    });

  const {
    data: sportMatchList,
    fetchNextPage: sportMatchFetchNextPage,
    hasNextPage: sportMatchHasNextPage,
    isLoading: sportMatchIsInitialLoading,
    isFetching: sportMatchIsFetching,
    isFetchingNextPage: sportMatchIsFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['matchList', 'sportMatch', selectedSportType, channel.channelId],
    queryFn: ({ pageParam = 0 }) =>
      getAllMatchList(
        getMatchQueries({
          country,
          disclosure: 'public',
          language:
            user && user.language ? user.language : window.navigator.language,
          timezone,
          sportType:
            selectedSportType === 'all' ? undefined : selectedSportType,
          offset: pageParam,
        }),
      ),
    ...infiniteQueryOptions,
    enabled: isProfileLoadProcessComplete,
  });

  useEffect(() => {
    if (initSportType !== 'all') setSelectedSportType(initSportType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initSportType]);

  useEffect(() => {
    const handleRouteChange = () => {
      sessionStorage.setItem(
        'sportPlusTab',
        JSON.stringify({
          scroll: window.scrollY,
          currentSport: selectedSportType,
        }),
      );
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events, selectedSportType]);

  useEffect(() => {
    if ('sportPlusTab' in sessionStorage) {
      const storageItem = sessionStorage.getItem('sportPlusTab');
      if (!storageItem) return;

      const { scroll, currentSport } = JSON.parse(storageItem);

      setSelectedSportType(currentSport || 'all');
      window.scrollTo(0, scroll);

      sessionStorage.removeItem('sportPlusTab');
    }
  }, []);

  useEffect(() => {
    let endPointCurrent = endPoint.current;

    const callBack = async (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        if (sportMatchHasNextPage && !sportMatchIsFetchingNextPage) {
          await sportMatchFetchNextPage();
        }
      }
    };

    const observer = new IntersectionObserver(callBack, {
      rootMargin: '100px',
    });

    if (endPoint.current) {
      endPointCurrent = endPoint.current;
      observer.observe(endPoint.current);
    }

    return () => {
      if (endPointCurrent) observer.unobserve(endPointCurrent);
    };
  }, [
    sportMatchFetchNextPage,
    sportMatchHasNextPage,
    sportMatchIsFetchingNextPage,
  ]);

  return (
    <>
      {initSportType === 'all' ? (
        <>
          <Box sx={{ position: 'relative', ml: { xs: 2, sm: 5, lg: 0 } }}>
            <SportsIconTabs
              currentSportType={selectedSportType}
              sportPlusTabTypeList={reorderedSportPlusTabTypeList}
              handleChangeSportsType={(selectedSportType: SportType) => {
                setSelectedSportType(selectedSportType);
              }}
            />
          </Box>
          <Divider
            sx={{
              borderColor: mode === 'dark' ? '#1C2120' : '#f2f5f3',
              borderWidth: { xs: '10px', sm: '2px' },
              ml: { sm: 5, lg: 0 },
            }}
          />
        </>
      ) : null}

      <EventCardWrapper
        eventListData={sportEventListData}
        isFetching={isSportEventListDataFetching}
        initSportType={selectedSportType}
      />

      <ResponsiveBannerAdCard adId="메인_첫번째컨텐츠리스트하단_배너_공통" />

      <Box
        sx={{
          mt: { xs: 3, sm: 5, lg: 9 },
          mx: { xs: 0, sm: '40px', lg: 0 },
        }}
      >
        <Typography
          variant="h3Bold"
          component="h3"
          sx={{
            mb: { xs: '10px', sm: '12px', lg: '22px' },
            mx: { xs: 2, sm: 0 },
            fontSize: { xs: '20px', sm: '24px' },
          }}
        >
          {t('subTitle.match')}
        </Typography>
        <PublicMatchListCardWrapper
          tabType="staffPick"
          resultPages={sportMatchList?.pages}
          isLoading={
            sportMatchIsInitialLoading ||
            (sportMatchIsFetching && !sportMatchIsFetchingNextPage)
          }
        />
      </Box>

      {sportMatchIsFetchingNextPage ? (
        <Box sx={{ mx: { xs: 0, sm: '40px', lg: 0 } }}>
          {!isMobile ? (
            <MatchCardSkeletons
              mode="default"
              sx={{ px: { xs: 0, sm: 2, lg: 0 } }}
            />
          ) : null}
          <Box textAlign="center">
            <CircularProgress size={30} sx={{ color: 'gray.500' }} />
          </Box>
        </Box>
      ) : (
        <Box ref={endPoint} />
      )}
    </>
  );
};

export default MainPageSport;
